import React, { useContext } from "react"
import styled from "styled-components"
import { md, lg } from "../breakpoints"
import { WeatherContext } from "./weather/weatherProvider"

const PrimaryDarkPath = styled.path`
  fill: var(--primary-dark);
  transition: fill 0.5s ease-in-out;
`
const PrimaryPath = styled.path`
  fill: var(--primary);
  transition: fill 0.5s ease-in-out;
`
const PrimaryDarkPolygon = styled.polygon`
  fill: var(--primary-dark);
  transition: fill 0.5s ease-in-out;
`

const MountainPaths = () => (
  <>
    <PrimaryDarkPath
      d="M230.97,75.02l0.16-17.01l0.17-3.73l0.78,0.08l1.01,20.98c0,0,11.1,4.11,21.76,7.44
 c10.66,3.33,18.89,4.07,20.22,4.73c1.33,0.67,7.46,4.59,4.65,3.5c-3.74-1.46-2.67,2.33-0.33,3s12.66,1,19.32,0.33
 s8.33-0.33,9.66-0.33c1.33,0,3,3.66,2,4.33c-1,0.67,11.3,3.28,13.26,4.51c1.96,1.22,5.87,1.96,9.79,1.96
 c3.91,0,15.9,4.16,18.84,5.87c2.94,1.71,2.2,2.45,7.34,0.98c5.14-1.47,6.12,3.43,6.12,3.43s5.14,0,7.34-0.49
 c2.2-0.49,12.97,0.73,17.61,3.18s9.79,7.58,11.5,8.56s4.89,2.94,10.76,2.94c5.87,0,8.56,4.16,10.76,4.65s7.34,1.22,10.76,0
 c3.43-1.22,9.54-4.16,11.74-2.69c2.2,1.47,6.61,0.73,7.09-2.2c0.49-2.94,4.16,0.24,4.16,0.24s5.63,1.96,7.83,2.94
 c2.2,0.98,8.23,4.67,11.19,5.46c2.96,0.79,11.25,0.2,14.4,1.58c3.16,1.38,6.91,4.14,6.91,5.13s2.57,2.37,4.34,1.58
 c1.78-0.79,26.84,0,26.84,0s18.15-0.59,19.54-0.59s10.06,2.37,12.04,6.71s2.57-1.78,2.57-1.78s1.58-3.75,5.33-1.78
 c3.75,1.97,12.23,8.29,15,10.26c2.76,1.97,11.84,4.54,13.42,4.54s25.46,10.06,32.16,14.6s9.47,7.1,9.47,7.1l3.88,214.35
 l-876.02-20.05l8.77-146c0,0,30.7-23.19,36.97-23.19s20.68-7.52,22.56-8.77c1.88-1.25,6.89-1.25,6.89-1.25s6.27-7.52,11.91-8.77
 c5.64-1.25,35.72-0.63,41.36-5.01c5.64-4.39,23.81-9.4,26.32-8.77c2.51,0.63,20.68-12.53,20.68-12.53s21.31-5.01,23.81-6.89
 c2.51-1.88,9.69-4.62,11.74-5.3c2.05-0.68,7.52-7.18,13.34-6.84c5.81,0.34,5.67,1.04,6.48,0.37s0.54-2.29,1.08-2.29
 s9.43,0.27,14.42-2.16s25.87-6.47,36.52-14.82c0,0,0-4.18,3.77-4.04c3.77,0.13,22.64-4.99,29.64-5.66
 c7.01-0.67,10.51-3.37,24.79-4.72c14.28-1.35,11.32-1.62,15.9-3.77c4.58-2.16,12.4-3.77,17.25-3.5s19.13-6.74,19.13-6.74
 s32.88-15.77,34.63-16.84C196.06,80.72,215.88,71.68,230.97,75.02z"
    />
    <g>
      <PrimaryPath
        d="M233.14,75.43l-1.01-20.98l-0.78-0.08l-0.17,3.73l-0.16,17.01l2.67,0.52
   C233.33,75.5,233.14,75.43,233.14,75.43z"
      />
      <PrimaryPath
        d="M638.52,185.1c0,0-2.76-2.57-9.47-7.1s-30.59-14.6-32.16-14.6s-10.66-2.57-13.42-4.54
   c-2.76-1.97-11.25-8.29-15-10.26c-3.75-1.97-5.33,1.78-5.33,1.78s-0.59,6.12-2.57,1.78c-1.97-4.34-10.66-6.71-12.04-6.71
   S529,146.03,529,146.03s-25.06-0.79-26.84,0c-1.78,0.79-4.34-0.59-4.34-1.58s-3.75-3.75-6.91-5.13s-11.44-0.79-14.4-1.58
   s-8.99-4.48-11.19-5.46c-2.2-0.98-7.83-2.94-7.83-2.94s-3.67-3.18-4.16-0.24c-0.49,2.94-4.89,3.67-7.09,2.2
   c-2.2-1.47-8.32,1.47-11.74,2.69c-3.42,1.22-8.56,0.49-10.76,0c-2.2-0.49-4.89-4.65-10.76-4.65c-2.08,0-3.82-0.25-5.28-0.61
   l-5.59,1.51l-13.05-0.93c0,0-9.01,0-11.5,0s-9.94,0.31-9.94-0.93c0-1.24-2.49-4.35-4.04-4.35s-8.39,0-10.56,0s-2.17-0.93-2.17-0.93
   l0.62-5.59l2.5-5.68c-0.42-0.29-0.93-0.65-1.66-1.08c-1.61-0.94-5.94-2.61-10.17-3.93l-4.21,0.99l-6.93,2.17l-10.4,1.73h-9.75
   l-5.41-3.03l1.08,1.95l0.87,2.38l-1.73,6.93l-0.43,4.98l-2.38-4.33v-5.2l-1.95-2.38l-4.12-2.82l-4.76-1.52l-5.41-3.25l-0.87-4.33
   l-3.47-1.52h-3.47l-1.52,0.43l-0.43,4.12v3.9v2.6l2.82,2.82l-2.17,6.28c0,0-1.73-0.87-1.73-1.52c0-0.65,0.65-2.38,0.65-2.38
   l-2.17-4.12l-1.73-0.22l-1.52-1.08l0.22-3.25l-0.87-3.25h-3.47l-1.3-1.52l0.65-2.17l-2.38-1.3l-2.17-1.95l-3.25-0.65l-3.68,0.22
   l-2.17-1.95l-4.33-1.52l-1.73-3.47l-4.76-1.52l-7.8-0.43l-5.41-1.73l-6.06-6.06l-1.95-1.73L220,74.53c-2.52,0.21-5.02,0.6-7.43,1.1
   L211,78.18l-0.43,2.98l0.37,2.81l-6.98-0.47l-2.98,1.07l-6.39,4.47l0.64,3.62c0,0-3.2,2.13-4.69,2.98s-4.47,0.85-4.47,0.85
   l-5.96,0.85L175,98.21l-7.46,5.54l-15.12,5.75l-6.39,1.49l-5.33-2.56l-8.52,0.64l-5.54,6.39l-8.95,2.13c0,0-0.64,0.64-6.6,0.64
   s-5.96,0-5.96,0l-13.63-0.21l-2.62-1.75c-2.04,0.53-3.95,0.97-6.23,1.19c-7.01,0.67-25.87,5.79-29.64,5.66
   c-3.77-0.13-3.77,4.04-3.77,4.04c-10.65,8.35-31.53,12.4-36.52,14.82c-4.99,2.43-13.88,2.16-14.42,2.16s-0.27,1.62-1.08,2.29
   c-0.81,0.67-0.67-0.02-6.48-0.37c-5.81-0.34-11.29,6.16-13.34,6.84c-2.05,0.68-9.23,3.42-11.74,5.3
   c-2.51,1.88-23.81,6.89-23.81,6.89s-18.17,13.16-20.68,12.53c-2.51-0.63-20.68,4.39-26.32,8.77c-5.64,4.39-35.72,3.76-41.36,5.01
   c-5.64,1.25-11.91,8.77-11.91,8.77s-5.01,0-6.89,1.25c-1.88,1.25-16.29,8.77-22.56,8.77c-6.27,0-36.97,23.19-36.97,23.19l-8.77,146
   l876.02,20.05L638.52,185.1z"
      />
    </g>
    <PrimaryDarkPath
      d="M211.72,86.65l4.75,0.19l0.57-1.52h2.85l2.47,1.52l-0.19,2.66c0,0-0.38,2.47,0.38,2.47c0.76,0,3.61,0,3.61,0
 l1.14,2.66l3.8,0.57c0,0,4.94-0.19,5.51-0.19c0.57,0,3.8,1.33,3.8,1.33l3.99,2.66l3.99,2.09l0.19,1.52l-5.7-0.57l-3.42-3.23
 l-1.9-1.52l-1.9,0.19l1.71,3.04l1.14,1.9l-2.09,0.95l-4.18-0.38l-2.09,0.95l0.19,2.09c0,0,0.95,6.27,0.95,7.41s-2.85,2.66-2.85,2.66
 s-0.57-1.52-0.95-5.51s-5.7-7.22-5.7-7.22l-1.52-2.47c0,0,0.57-2.85,0-2.85c-0.57,0-0.38,0-2.66,0c-2.28,0-1.52-0.19-2.28,0
 c-0.76,0.19,2.85,5.13,2.85,5.13l-2.05,2.32l-3.91,0.29l-4.78-4.2c0,0-2.17-2.61-2.61-2.61s-4.93-3.48-4.93-3.48l-0.43-4.2l3.33-1.3
 c0,0,3.91-2.03,4.93-2.17C208.74,87.69,211.72,86.65,211.72,86.65z"
    />
    <PrimaryDarkPolygon
      points="254.57,122.4 257.03,122.57 262.77,122.98 258.67,128.26 262.48,130.31 267.76,128.84 263.07,138.22 
 257.5,139.98 256.91,137.05 259.55,133.53 254.86,131.19 254.86,127.96 "
    />
    <PrimaryDarkPolygon
      points="173.94,106.16 159.11,116.48 151.37,120.99 144.93,120.99 133.97,121.64 146.22,115.19 158.46,110.68 
 170.58,105.51 "
    />
    <PrimaryDarkPolygon
      points="107.88,125.59 79.67,132.2 70.86,134.85 66.01,137.93 61.17,136.17 57.64,137.93 58.96,142.78 
 63.37,143.22 73.94,139.7 84.96,133.97 98.18,131.32 108.43,131.72 110.56,128.85 110.88,127.15 "
    />
  </>
)

const MountainSVG = styled.svg`
  width: 100%;
  bottom: 0;
  position: absolute;
  margin-bottom: -140px;
  z-index: 4;
  height: 350px;
  transition: filter 0.5s ease-in-out;
  filter: ${(props) => props.shadow};
  ${md`
    height: 380px;
  `}
  ${lg`
    height: 450px;
  `}
`

const Mountain = () => {
  const { state = {} } = useContext(WeatherContext)

  let shadow = `drop-shadow(0px 0px 90px var(--accent))`

  if (!state.darkMode) {
    const hasRain =
      state.weather?.includes("Rain") || state.weather?.includes("Drizzle")
    if (hasRain) {
      shadow = `drop-shadow(0px 0px 90px rgba(0, 0, 0, 0.4))`
    } else {
      shadow = `drop-shadow(0px 0px 90px rgba(54, 0, 161, 0.4))`
    }
  }
  return (
    <MountainSVG shadow={shadow} x="0px" y="0px" viewBox="0 0 457.76 251.52">
      <MountainPaths></MountainPaths>
    </MountainSVG>
  )
}

export default Mountain
